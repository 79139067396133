import { getWooTitleId, formateMywDate } from "../helpers.js";
import { CreateDialog } from "../dialog";

/**
 * Represents a navigation overlay for a loyalty program.
 * @class
 */
class NavigationOverlay {
	constructor(options = {}) {
		const { navigator, title, triggerClass } = options;

		this.navigator =
			navigator || document.querySelector(".js-loyalty-login-overlay");
		this.title =
			title || document.querySelector(".js-loyalty-login-overlay-title");
		this.triggerClass = triggerClass || ".js-open-loyalty-login, #js-open-loyalty-login";

		this.startPage = "";
		this.currentPage = {
			route: "",
			element: "",
		};

		this.history = [];
		this.pages = [];
	}

	init() {
		if (!this.navigator) return;

		this.startPage = this.navigator.getAttribute("page-start");

		this.currentPage = {
			route: this.startPage,
			element: this.navigator.querySelector(`*[page=${this.startPage}]`),
		};

		this.pages = this.navigator.querySelectorAll("*[page]");

		this.start();
	}

	start() {
		this.addHistory(this.currentPage.route);
		this.setTitle();
		this.showPage(this.currentPage.element);

		document.querySelectorAll("*[route-link]").forEach((link) => {
			link.addEventListener("click", (event) => {
				event.preventDefault();

				let targetRoute = link.getAttribute("route-link");
				if (!targetRoute) return;

				this.openPage(targetRoute);
			});
		});

		document.querySelectorAll("*[route-close]").forEach((closeBtn) => {
			closeBtn.addEventListener("click", (event) => {
				if (this.navigator.getAttribute("refresh-on-close") == "true") {
					this.navigator.close();
					return location.reload();
				}

				return this.navigator.close();
			});
		});

		document.body.addEventListener("click", (event) => {
			if (event.target.matches(this.triggerClass)) {
				event.preventDefault();

				return this.navigator.showModal();
			}
		});
	}

	setTitle() {
		const title = this.currentPage.element.getAttribute("page-title");

		if (!title) return this.hideBackButton();

		this.title.innerHTML = title;
		this.setBackButton();
	}

	setBackButton() {
		if (this.currentPage.route == this.startPage) return;

		const button = this.navigator.querySelector(".js-back-button");
		button.classList.remove("d-none");

		button.addEventListener("click", () => {
			this.back();
		});
	}

	hideBackButton() {
		const button = this.navigator.querySelector(".js-back-button");
		button.classList.add("d-none");
	}

	addHistory(route) {
		this.history.push(route);
		return this.history;
	}

	removeHistory(route) {
		let filtered = this.history.filter(function (e) {
			return e !== route;
		});
		this.history = filtered;

		return this.history;
	}

	back() {
		this.removeHistory(this.history[this.history.length - 1]);
		this.openPage(this.history[this.history.length - 1]);

		return true;
	}

	openPage(route) {
		if (!route || route == this.currentPage.route) return false;

		const nextPage = this.navigator.querySelector(`*[page=${route}]`);

		this.currentPage = {
			route: nextPage.getAttribute("page"),
			element: nextPage,
		};

		this.addHistory(this.currentPage.route);

		this.setTitle();
		this.showPage(this.currentPage.element);

		return true;
	}

	showPage(page) {
		if (!page) return false;

		this.pages.forEach((page) => {
			page.classList.add("hidden");
		});
		page.classList.remove("hidden");

		return true;
	}
}

/**
 * Handles navigation events and form updates.
 */
const NavigationEvents = () => {
	const updateFormValues = (userData) => {
		if (!userData) return;

		const fields = [
			{ key: "salutation", value: "#update_salutation" },
			{ key: "firstname", value: "#update_firstname" },
			{ key: "lastname", value: "#update_lastname" },
			{ key: "email", value: "#update_email" },
			{ key: "date_of_birth", value: "#update_birthdate" },
			{ key: "street", value: "#update_street" },
			{ key: "house_number", value: "#update_housenumber" },
			{ key: "postcode", value: "#update_zip" },
			{ key: "city", value: "#update_city" },
		];

		const updateField = (field, value) => {
			field.val(`${value}`);
			return field.trigger("change");
		};

		fields.forEach((element) => {
			if (userData?.[element.key] == undefined) return;

			let $element = $(element.value);
			if (!$element.length) return;

			if (element.key == "salutation")
				return updateField($element, getWooTitleId(userData[element.key]));
			if (element.key == "date_of_birth")
				return updateField($element, formateMywDate(userData[element.key]));

			return updateField($element, userData[element.key]);
		});
	};

	// User login
	document
		.querySelector("form#overlayLogin")
		?.addEventListener("submit", (e) => {
			e.preventDefault();

			const formData = new FormData(e.target);

			let data = {
				action: "loyalty_login_action",
				email: formData.get("login"),
				password: formData.get("password"),
			};

			if (!data.email.length || !data.password.length) {
				return CreateDialog({
					id: "overlayLoginError",
					headline: "Fehler",
					message: "E-Mail-Adresse und Passwort sind Pflichtfelder",
					button: "OK",
				});
			}

			jQuery.ajax({
				type: "POST",
				dataType: "json",
				url: globals.api_url,
				data: data,
				success: function (res) {
					if (!res.success)
						return CreateDialog({
							id: "overlayLoginError",
							headline: "Fehler",
							message: res.message,
							button: "OK",
						});

					let route = "update";
					if (
						!res.data.is_loyalty_member &&
						res.data.loyalty_member_requested
					) {
						route = "new-friend";
					}

					if (res.data.is_loyalty_member && res.data.loyalty_member_requested) {
						route = "friend";
					}

					window.navigationOverlay.startPage = route;
					window.navigationOverlay.openPage(route);

					updateFormValues(res.data);
					window.navigationOverlay.navigator.setAttribute(
						"refresh-on-close",
						true
					);
				},
				error: function (res) {
					return CreateDialog({
						id: "overlayLoginError",
						headline: "Fehler",
						message:
							"Es ist leider ein Fehler aufgetreten, bitte versuche es erneut.",
						button: "OK",
					});
				},
			});
		});

	// User update
	document
		.querySelector("form#overlayUpdate")
		?.addEventListener("submit", (e) => {
			e.preventDefault();

			const formData = new FormData(e.target);

			let data = {
				action: "loyalty_update_action",
				salutation: formData.get("update_salutation"),
				firstname: formData.get("update_firstname"),
				lastname: formData.get("update_lastname"),
				email: formData.get("update_email"),
				telephone: formData.get("update_telephone"),
				street: formData.get("update_street"),
				house_number: formData.get("update_house_number"),
				postcode: formData.get("update_zip"),
				city: formData.get("update_city"),
				date_of_birth: formData.get("update_birthdate"),
				is_loyalty_member: formData.get("update_loyalty_register") ? 1 : 0,
				is_subscribed: formData.get("update_newsletter") ? 1 : 0,
			};

			if (!data.email.length) {
				return CreateDialog({
					id: "overlayUpdateError",
					headline: "Fehler",
					message: "E-Mail-Adresse ist ein Pflichtfeld",
					button: "OK",
				});
			}

			jQuery.ajax({
				type: "POST",
				dataType: "json",
				url: ajaxurl,
				data: data,
				success: function (res) {
					load.close();
					if (!res.success)
						return CreateDialog({
							id: "overlayUpdateError",
							headline: "Loyalty Fehler",
							message: res.message,
							button: "OK",
						});

					const route = "new-friend";

					window.navigationOverlay.startPage = route;
					window.navigationOverlay.openPage(route);

					window.navigationOverlay.navigator.setAttribute(
						"refresh-on-close",
						true
					);
				},
				error: function (res) {
					return CreateDialog({
						id: "overlayUpdateError",
						headline: "Login Fehler",
						message:
							"Es ist leider ein Fehler aufgetreten, bitte versuche es erneut.",
						button: "OK",
					});
				},
			});
		});

	// User register
	document
		.querySelector("form#overlayRegister")
		?.addEventListener("submit", (e) => {
			e.preventDefault();

			const formData = new FormData(e.target);
			let data = {
				action: "loyalty_register_action",
				reg_salutation: formData.get("reg_salutation"),
				reg_firstname: formData.get("reg_firstname"),
				reg_lastname: formData.get("reg_lastname"),
				email: formData.get("reg_email"),
				street: formData.get("reg_street"),
				housenumber: formData.get("reg_house_number"),
				reg_telephone: formData.get("reg_telephone"),
				zip: formData.get("reg_zip"),
				city: formData.get("reg_city"),
				reg_birthdate: formData.get("reg_birthdate"),
				loyalty_register: formData.get("loyalty_register") ? "on" : 0,
				reg_newsletter: formData.get("reg_newsletter") ? "on" : 0,
				password: formData.get("reg_password"),
				reg_password: formData.get("reg_password"),
				reg_data_privacy: formData.get("reg_data_privacy") ? true : false,
				no_redirect: true,
			};

			if (!data.email.length) {
				return CreateDialog({
					id: "overlayRegisterError",
					headline: "Fehler",
					message: "E-Mail-Adresse ist ein Pflichtfeld",
					button: "OK",
				});
			}

			if (!$("#reg_data_privacy").is(":checked")) {
				return CreateDialog({
					id: "overlayRegisterError",
					headline: "Fehler",
					message: "Du musst unseren Datenschutz & AGB zustimmen",
					button: "OK",
				});
			}

			if (data.password !== formData.get("passwordRepeat")) {
				return CreateDialog({
					id: "overlayRegisterError",
					headline: "Fehler",
					message:
						"Dein Passwort muss übereinstimmen und mindestens 8 Zeichen lang sein",
					button: "OK",
				});
			}

			jQuery.ajax({
				type: "POST",
				dataType: "json",
				url: globals.api_url,
				data: data,
				success: function (res) {
					if (!res.success)
						return CreateDialog({
							id: "overlayRegisterError",
							headline: "Fehler",
							message: res.message,
							button: "OK",
						});

					const route = "new-friend";

					window.navigationOverlay.startPage = route;
					window.navigationOverlay.openPage(route);

					window.navigationOverlay.navigator.setAttribute(
						"refresh-on-close",
						true
					);
				},
				error: function (res) {
					return CreateDialog({
						id: "overlayRegisterError",
						headline: "Fehler",
						message:
							"Es ist leider ein Fehler aufgetreten, bitte versuche es erneut.",
						button: "OK",
					});
				},
			});
		});

	// disable submit button if form is invalid
	document
		.querySelectorAll("form#overlayLogin, form#overlayRegister")
		?.forEach((form) => {
			form.addEventListener("input", (event) => {
				const submit = form.querySelector("button[type=submit]");

				if (form.checkValidity()) {
					submit.removeAttribute("disabled");
				} else {
					submit.setAttribute("disabled", "true");
				}
			});
		});
};

window.navigationOverlay = new NavigationOverlay();
window.navigationOverlay.init();

NavigationEvents();
